<template>
  <div class="view-box" style="height: 800px">
    <div class="view-box-title">报抽排行</div>
    <div class="view-box-content" id="scroll-board" style="padding: 0px">
      <dv-scroll-board :config="config" style="height: 435px" hoverPause indexHeader="排名"/>
    </div>
  </div>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'LeftThree',
    data () {
      return {
        config: {
          header: ['排名', '集污点编号', '集污点类型', '容积/m³', '上报次数'],
          data: [
            ['1', 'bj5624', '集污池', '5吨', '4'],
            ['2', 'ot5645', '三格化粪池', '13吨', '9'],
            ['3', 'bj5624', '单翁', '10吨', '4'],
            ['4', 'ot5645', '三格化粪池', '5吨', '9'],
            ['5', 'bj5624', '三格化粪池', '13吨', '4'],
            ['6', 'ot5645', '集污池', '5吨', '9'],
            ['7', 'bj5624', '三格化粪池', '10吨', '4'],
            ['8', 'ot5645', '单翁', '13吨', '9'],
            ['9', 'bj5624', '集污池', '5吨', '4'],
            ['10', 'ot5645', '三格化粪池', '5吨', '9'],
            ['11', 'bj5624', '三格化粪池', '13吨', '4'],
            ['12', 'ot5645', '三格化粪池', '5吨', '9'],
            ['13', 'bj5624', '集污池', '10吨', '4'],
            ['14', 'ot5645', '三格化粪池', '13吨', '9'],
            ['15', 'bj5624', '三格化粪池', '13吨', '4'],
            ['16', 'ot5645', '集污池', '13吨', '9'],
            ['17', 'bj5624', '三格化粪池', '13吨', '4'],
            ['18', 'ot5645', '单翁', '5吨', '9'],
            ['19', 'bj5624', '集污池', '13吨', '4'],
            ['20', 'ot5645', '单翁', '5吨', '9']
          ],
          index: false,
          columnWidth: [80, 200, 120, 100, 100],
          align: ['center'],
          headerHeight: 35,
          rowNum: 10,
          carousel: 'page',
          headerBGC: 'rgba(0, 44, 81, 0.8)',
          oddRowBGC: 'rgba(0, 44, 81, 0.8)',
          evenRowBGC: 'rgba(10, 29, 50, 0.8)'
        }
      }
    },
    created () {
      this.getReportRankData()
    },
    methods: {
      getReportRankData () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchDatavApi.getReportRankData,
          noTips: true,
          loading: 'no',
          success: (data) => {
            this.config = {
              header: ['排名', '集污点名称', '集污点类型', '容积/m³', '上报次数'],
              data: data.body,
              index: false,
              columnWidth: [80, 200, 120, 100, 100],
              align: ['center'],
              headerHeight: 35,
              rowNum: 10,
              carousel: 'page',
              headerBGC: 'rgba(0, 44, 81, 0.8)',
              oddRowBGC: 'rgba(0, 44, 81, 0.8)',
              evenRowBGC: 'rgba(10, 29, 50, 0.8)'
            }
          },
          error: () => {
          }
        })
      }
    }
  }
</script>